import { getProducts } from "../../services/products";
import { uploadSerialsByProductId } from "../../services/serials";
import "./serials.css";

import { useEffect, useState } from "react"

export default function Serials()
{
    const [ productOptions, setProductOptions ]         = useState([]);
    const [ selectedProductId, setSelectedProductId ]   = useState();
    const [ formData, setFormData ]                     = useState({})


    const onload = async () =>
    {
        const getProductsResponse = await getProducts();
        console.log(getProductsResponse.data);

        let tempOptions = getProductsResponse.data.map(product => {
            return <option value={product.productId}>{product.title}</option>
        });

        setProductOptions(tempOptions);
    }

    const handleOnChange = (e) => {
        setSelectedProductId(e.target.value);
    }

    const uploadSerials = async () => {
        if(!selectedProductId)
        {
            return alert("Select a Product");
        }

        if(!formData.serialId)
        {
            return alert("Enter Serials");
        }

        const response = await uploadSerialsByProductId(formData, selectedProductId);

        if(response.status === 201)
        {
            alert(`Uploaded ${response.data} serials`);
        }else{
            alert("Error");
        }
        window.location.reload();
    }

    const handleChange = (e) => 
    {
        let { name, value } = e.target;
        setFormData(
            {
                [name]: value
            }
        )
    }

    useEffect(() => {
        onload();
    }, [])

    return (
        <div className="product-page-cont">
            <div className="product-page-header-cont">
                <h2 className="dashboard-page-header">Serials</h2>
                <div className="product-page-item" onClick={uploadSerials}>Upload Serials</div>
            </div>
            <div className="serial-uploader-cont">
                <textarea name="serialId" className='serial-uploader-text-area' onChange={handleChange}/>
                <select class="serial-selector" onChange={handleOnChange}>
                    <option>
                        Select a Product
                    </option>
                    {productOptions}
                </select>
            </div>
        </div>
    )
}
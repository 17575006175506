import './App.css';

import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import Dashboard from "./components/dashboard/dashboard";

import { getCookie } from "./util/cookies";

function App() 
{
  useEffect(() => 
  {
    const UTOK = getCookie("UTOK");

    if (UTOK) {
      const tokenParts = UTOK.split(".");

      const payload = JSON.parse(atob(tokenParts[1]));

      if(!payload.authorities.includes("ADMIN"))
        window.location.href = "http://altsgreat.com";
    }else{
      window.location.href = "http://altsgreat.com";
    }
  }, [])

  return (
    <Routes>
      <Route path="/*" element={<Dashboard />} />
    </Routes>
  );
}

export default App;

import { RouteConstants } from "../constants/Routes";

import api from "./apiConfig";

export const uploadSerialsByProductId = async (serials, productId) => {
    try {
        const response = await api.post(RouteConstants.SERIAL_URL + "/" + productId, serials);
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}
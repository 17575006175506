import { useState } from "react"
import "./products.css"

export default function Products()
{
    const [ products, setProducts ] = useState([]); 

    return (
        <div className="product-page-cont">
            <div className="product-page-header-cont">
                <h2 className="dashboard-page-header">Products</h2>
                <div className="product-page-item">Create Product</div>
            </div>
            <div className="product-page-"></div>
        </div>
    )
}
import { Route, Routes } from "react-router";
import Navbar from "../navbar/navbar";

import "./dashboard.css";
import Products from "../products/products";
import Serials from "../serials/serials";

export default function Dashboard()
{
    return (
        <div className="dashboard-cont">
            <Navbar/>
            <div className="dashboard-page-cont">
                <Routes>
                    <Route path={"/products/*"} element={<Products/>}/>
                    <Route path={"/orders/*"}/>
                    <Route path={"/serials/*"} element={<Serials/>}/>
                    <Route path={"/blacklists/*"}/>
                </Routes>
            </div>
        </div>
    )
}
import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../util/cookies";
import { RouteConstants } from "../constants/Routes";

const api = axios.create({
  baseURL: RouteConstants.BACKEND_URL,
  validateStatus: (s) => s >= 200 && s < 400
});

/**
 * Intercept request to the server
 */
api.interceptors.request.use(
  request => {
    // Check if the request is to login or a authorization request
    const authorization = getCookie("UTOK");

    if(authorization)
    {
      request.headers.Authorization = authorization;
    }
    return request;
  }
)

/**
 * Intercept responses from the server
 */
api.interceptors.response.use(
  response => {

    if(response.headers.utok_status && response.headers.utok_status === "INVALID")
    {
      deleteCookie("UTOK")
      window.location.reload()
    }

    const responseCookies = response.headers.get("Set-Cookie");
    
    if(responseCookies)
    {
      responseCookies.split(";").forEach(cookie => {
        setCookie(cookie.split("=")[0], cookie.split("=")[1])
      })
    }
    return response;
  },

  async (error) =>
  {
    const response = error.response;

    if(error.response.headers.utok_status && error.response.headers.utok_status === "INVALID")
    {
      deleteCookie("UTOK")
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

export default api;
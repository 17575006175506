/**
 * Author: GreatAlts
 * 
 * Purpose: Store the code for the navbar in the dashboard.
 */
import { Link } from "react-router-dom"
import "./navbar.css"

export default function Navbar()
{
    return (
        <div className="navbar-cont">
            <Link to={"/"} className="navbar-item-cont">
                Overview
            </Link>
            <Link to={"/products"} className="navbar-item-cont">
                Products
            </Link>
            <Link to={"/orders"} className="navbar-item-cont">
                Orders
            </Link>
            <Link to={"/serials"} className="navbar-item-cont">
                Serials
            </Link>
            <Link to={"/blacklits"} className="navbar-item-cont">
                Blacklist
            </Link>
        </div>
    )
}
import { RouteConstants } from "../constants/Routes";

import api from "./apiConfig";

/**
 * Send a request to the backend to get all the products that aren't accounts.
 * 
 * @returns Array of products that aren't accounts.
 */
export const getProducts = async () => 
{
    try {
        const response = await api.get(`${RouteConstants.PRODUCTS_URL}/regular`)
        return response;
    }catch(err){
        console.log(err);
        return err.response;
    }
}